<template>
  <div class="notification-container">
    <div class="bell-wrapper">
      <button ref="bellIcon" @click="toggleNotifications" class="bell-icon">
        🔔
        <span v-if="notificationCount > 0" class="badge">{{ notificationCount }}</span>
      </button>
      <div v-if="showNotifications" ref="popover" class="popover">
        <ul class="border">
          <li class="text-center" style="text-decoration: none;color: gray;" @click="clearAll">Clear All</li>
        </ul>
        <ul>
          <li v-for="(notification, index) in notifications" :key="index" class="d-flex justify-content-between">
            <span @click="markAsRead(notification.id)">
              {{ notification.data.msg }}
            </span>
            <span><i class="fa-solid fa-xmark float-right mt-1 text-danger"
                @click="removeItem(notification.id)"></i></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
export default {
  data() {
    return {
      showNotifications: false,
      notificationCount: 0, // Static notification count
      notifications: [],
      userId: JSON.parse(atob(localStorage.getItem('user'))).id,
    };
  },
  computed: {
    hasNotifications() {
      return this.notificationCount > 0;
    },
  },
  methods: {
    clearAll() {
      let _vm = this;
      _vm.axios.post('user-notification-read', { ids: _vm.notifications.map(notification => notification.id) })
        .then(function (response) {
          localStorage.removeItem('notifications');
          _vm.notifications = [];
          _vm.notificationCount = 0;
          _vm.$emit('clear-all-notifications');
          _vm.showNotifications = false;
        })
        .catch(function (error) {
          console.error('Error clearing all notifications:', error);
        });
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    handleClickOutside(event) {
      const bellIcon = this.$refs.bellIcon;
      const popover = this.$refs.popover;
      if (popover && !popover.contains(event.target) && !bellIcon.contains(event.target)) {
        this.showNotifications = false;
      }
    },
    addNotification(notification) {
      const exists = this.notifications.some(n => n.id === notification.id);
      if (!exists) {
        this.notifications.unshift(notification);
        this.notificationCount = this.notifications.length;
        localStorage.setItem("notifications", JSON.stringify(this.notifications));
        this.$emit('new-notification', this.notificationCount);
      }
    },
    removeItem(id) {
      let _vm = this;
      const idArray = [id];
      _vm.axios.post('user-notification-read/', { ids: idArray })
        .then(function (response) {
          let notifications = JSON.parse(localStorage.getItem('notifications')) || [];
          notifications = notifications.filter(item => !idArray.includes(item.id));
          localStorage.setItem('notifications', JSON.stringify(notifications));
          _vm.notifications = notifications;
          _vm.notificationCount = _vm.notifications.length;
        })
        .catch(function (error) {
          console.error('Error removing notification:', error);
        });
    },
    markAsRead(id) {
      let _vm = this;
      const ids = [id];
      _vm.axios
        .post(`user-notification-read`, { ids: ids })
        .then(function (response) {
          let notifications = JSON.parse(localStorage.getItem('notifications')) || [];
          ids.forEach(id => {
            const notification = notifications.find(n => n.id === id);
            if (notification) {
              let route = '';
              switch (notification.type) {
                case 'App\\Events\\NewLeadGenerated':
                  route = `/lead/${notification.data.lead_id}#price-quotes`;
                  break;
                case 'App\\Events\\LeadTransferred':
                  route = `/lead/${notification.data.lead_id}#price-quotes`;
                  break;
                case 'App\\Notifications\\LeadAssignmentNotification':
                  route = `/lead/${notification.data.lead_id}#price-quotes`;
                  break;
                case 'App\\Notifications\\LeadUnassignmentNotification':
                  route = `/lead/${notification.data.lead_id}#info`;
                  break;
                case 'App\\Notifications\\SMSNotification':
                  if (notification.data.client_id) {
                    route = `/lead/${notification.data.lead_id}#lead-message`;
                  } else if (notification.data.old_client_id) {
                    route = `/old-clients`;
                  }
                  break;
                case 'App\\Notifications\\LeadChatNotification':
                  // route = `/lead/${notification.data.lead_id}#info`;
                  break;
              }
              if (route) {
                window.open(route, '_blank');
              }
              notifications = notifications.filter(n => n.id !== id);
            }
          });
          localStorage.setItem('notifications', JSON.stringify(notifications));
          _vm.notifications = notifications;
          _vm.notificationCount = _vm.notifications.length;

        })
        .catch(function (error) {
          console.error('Error:', error);
        });
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    const storedNotifications = localStorage.getItem("notifications");
    if (storedNotifications) {
      try {
        this.notifications = JSON.parse(storedNotifications);
        this.notificationCount = this.notifications.length;
      } catch (e) { }
    }
    this.$echo.private("new-lead-notification")
      .listen("NewLeadGenerated", (notification) => {
        EventBus.$emit('NewLeadGenerated', notification);
        this.addNotification(notification);
      })
      .listen("NewLeadAssigned", (notification) => {
        EventBus.$emit('NewLeadAssigned', notification);
      })
      .listen("UnassignLeadTaken", (notification) => {
        EventBus.$emit('UnassignLeadTaken', notification);
      });
    this.$echo.private("lead-transferred")
      .listen("LeadTransferred", (notification) => {
        this.addNotification(notification);
      });

    this.$echo.private('App.Models.User.' + this.userId)
      .notification((notification) => {
        if (notification.type == 'App\\Notifications\\PqOpenNotification') {
          EventBus.$emit('PqOpenNotification', notification);
        } else if (notification.type == 'App\\Notifications\\AutoDialerNotification') {
          window.open(`/take-lead/${notification.data.id}`, '_blank');
        } else if (notification.type == 'App\\Notifications\\NewUserStatus') {
          EventBus.$emit('NewUserStatus', notification);
          this.addNotification(notification);
        }
        else {
          this.addNotification(notification);
        }
      });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.notification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  position: absolute;
  z-index: 9999;
}

.bell-wrapper {
  position: relative;
}

.bell-icon {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: -24px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px 4px;
  font-size: 12px;
}

.popover {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  width: 300px;
  padding: 10px;
}

.popover ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.popover li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.popover li:last-child {
  border-bottom: none;
}
</style>

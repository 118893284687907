<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="{ name: 'dashboard.home-1' }" class="logo">
            <div class="iq-light-logo">
              <img src="../../../../assets/images/logo.gif" class="img-fluid" alt="logo" />
            </div>
            <div class="iq-dark-logo">
              <img src="../../../../assets/images/logo-dark.gif" class="img-fluid" alt="logo" />
            </div>
            <span>{{ appName }}</span>
          </router-link>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="navbar-left">
          <div class="iq-search-bar d-none d-md-block"></div>
        </div>
        <b-navbar-toggle target="nav-collapse" class="">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <div class="iq-menu-bt align-self-center" v-if="$route.meta.layout !== 'two-sidebar'">
          <div class="wrapper-menu" @click="sidebarMini">
            <div class="main-circle">
              <i class="ri-arrow-left-s-line"></i>
            </div>
            <div class="hover-circle">
              <i class="ri-arrow-right-s-line"></i>
            </div>
          </div>
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item" v-nav-toggle>
              <a class="search-toggle iq-waves-effect language-title" href="#"><img :src="selectedLang.image"
                  alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px" /><i
                  class="ri-arrow-down-s-line"></i></a>
              <div class="iq-sub-dropdown">
                <a class="iq-sub-card" href="javascript:void(0)" v-for="(lang, i) in langsOptions" :key="`Lang${i}`">
                  <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" />{{ lang.title }}
                </a>
              </div>
            </li>
            <li class="nav-item" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect">
                <lottie :option="require('../../../../assets/images/small/lottie-bell')
                  " id="lottie-beil" />
                <span class="bg-danger dots"></span>
              </a>
            </li>
          </ul>
        </b-collapse>
        <ul class="navbar-list">
          <li class="rounded" v-nav-toggle>
            <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
              <img :src="user.full_image_url" v-if="user.full_image_url" class="img-fluid rounded" alt="user" />
              <img src="../../../../assets/images/user/pngwing.com.png" v-else class="img-fluid rounded" alt="user" />
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0" style="height: 350px; overflow-x: hidden;">
                <div class="iq-card-body p-0">
                  <div class="bg p-2" style="
                                                      line-height: normal;

                                                      height: 5rem;
                                                    ">
                    <div class="float-left ml-3">
                      <h6 class="mb-0 text-white line-height name">
                        <a>{{ user.first_name + " " + user.last_name }}</a>
                      </h6>
                      <span class="text-white font-size-12"><a>{{ user.contact_number }}</a></span>
                      <h6 class="text-white font-size-12 mb-2">
                        <a>{{ user.email }}</a>
                      </h6>
                    </div>
                    <!-- <FloatingButton /> -->
                    <div class="float-right">
                      <input type="checkbox" id="switch" /><label for="switch">Toggle</label>
                    </div>
                  </div>
                  <router-link to="/systems" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-solid fa-address-book"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">System</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/user-logs" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-solid fa-address-book"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">User Logs</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/agent-list" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-solid fa-list-ul"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">Agent List</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-brands fa-wikipedia-w"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">Wiki</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/statistics" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-solid fa-chart-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">Statistics</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-solid fa-calendar-plus"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">Schedule</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/change-password" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="fa-solid fa-lock"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0">Change Password</h6>
                      </div>
                    </div>
                  </router-link>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="btn btn-primary dark-btn-primary" href="javascript:void(0)" @click="logout"
                      role="button">signout<i class="ri-login-box-line ml-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { core, APPNAME } from "../../../../config/pluginInit";
import Lottie from "../../../../components/core/lottie/Lottie";
import { _ } from "vue-underscore";
import FloatingButton from "../../../../layouts/Components/FloatingButton.vue";

export default {
  name: "HeaderStyle",
  components: {
    Lottie,
    FloatingButton,
  },
  data() {
    return {
      appName: APPNAME,
      apiUrl: process.env.VUE_APP_API_URL.replace("/api", ""),
      sessionTimer: null,
      sessionTimeout: 5 * 60 * 1000,
    };
  },
  mounted() {
    if (!this.horizontal) {
      document.addEventListener("click", this.closeSearch, true);
    }
  },
  methods: {
    sidebarMini() {
      core.triggerSet();
    },
    //
    // startSessionTimer() {
    //   this.sessionTimer = setTimeout(() => {
    //     this.logout();
    //   }, this.sessionTimeout);

    //   let idleTimer = null;
    //   const resetIdleTimer = () => {
    //     clearTimeout(idleTimer);
    //     idleTimer = setTimeout(() => {
    //       this.logout();
    //     }, this.sessionTimeout);
    //   };
    //   document.addEventListener('mousemove', resetIdleTimer);
    //   document.addEventListener('keydown', resetIdleTimer);
    //   document.addEventListener('mousedown', resetIdleTimer);
    //   resetIdleTimer();
    // },
    // beforeDestroy() {
    //   clearTimeout(this.sessionTimer);
    //   document.removeEventListener('mousemove', this.resetSessionTimer);
    //   document.removeEventListener('keydown', this.resetSessionTimer);
    //   document.removeEventListener('mousedown', this.resetSessionTimer);
    //   clearTimeout(idleTimer);
    // },
    //
    logout() {
      const _vm = this;
      this.axios
        .get("/logout")
        .then(function () {
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
          localStorage.removeItem('all_leads');
          localStorage.removeItem('targetUpdated');
          localStorage.removeItem('all_sales');
          localStorage.removeItem('rc_widget');
          localStorage.removeItem('call_queue_presence');
          localStorage.removeItem('subscribedLeads');
          localStorage.removeItem('user');
          localStorage.removeItem('menu');
          localStorage.removeItem('slugs');
          window.location.href = "/auth/login";
        })
        .catch(function (error) {
        });
    },
    ...mapActions({
      rtlAdd: "Setting/setRtlAction",
      rtlRemove: "Setting/removeRtlAction",
    }),
  },

  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    ...mapGetters({
      bookmark: "Setting/bookmarkState",
      langsOptions: "Setting/langOptionState",
      selectedLang: "Setting/langState",
    }),
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 75px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+label {
  background: #bada55;
}

input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 65px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.iq-card.shadow-none.m-0::-webkit-scrollbar {
  display: none;
}
</style>

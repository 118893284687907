var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-collapse',{class:_vm.className,attrs:{"tag":"ul","visible":_vm.open,"id":_vm.idName,"accordion":_vm.accordianName}},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,class:!_vm.hideListMenuTitle
          ? 'p-0'
          : item.is_heading
          ? 'iq-menu-title'
          : _vm.activeLink(item) && item.children
          ? 'active'
          : _vm.activeLink(item)
          ? 'active'
          : ''},[(item.is_heading && _vm.hideListMenuTitle)?_c('i',{staticClass:"ri-subtract-line"}):_vm._e(),(item.is_heading && _vm.hideListMenuTitle)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(!item.is_heading)?_c('router-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.getId(item)),expression:"getId(item)"}],class:`iq-waves-effect ${
          _vm.activeLink(item) && item.children
            ? 'active'
            : _vm.activeLink(item)
            ? 'active'
            : ''
        }`,attrs:{"to":item.link}},[(item.is_icon_class)?_c('i',{class:item.icon}):void 0,_c('span',[_vm._v(_vm._s(item.name))]),(item.children)?_c('i',{staticClass:"ri-arrow-right-s-line iq-arrow-right"}):_vm._e(),(_vm.hideListMenuTitle)?_c('small',{class:item.append_class,domProps:{"innerHTML":_vm._s(item.append)}}):_vm._e()],2):_vm._e(),(item.children)?_c('List',{class:`iq-waves-effect ${
          _vm.activeLink(item) && item.children
            ? 'active'
            : _vm.activeLink(item)
            ? 'active'
            : ''
        }`,attrs:{"items":item.children,"sidebarGroupTitle":_vm.hideListMenuTitle,"open":item.link.name !== '' && _vm.activeLink(item) && item.children
            ? true
            : !!(item.link.name !== '' && _vm.activeLink(item)),"idName":_vm.getId(item),"accordianName":`sidebar-accordion-${_vm.getId(item)}`,"className":_vm.getClassName(item)}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
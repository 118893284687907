<template>
<b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li v-for="(item, index) in items" :key="index" :class="
        !hideListMenuTitle
          ? 'p-0'
          : item.is_heading
          ? 'iq-menu-title'
          : activeLink(item) && item.children
          ? 'active'
          : activeLink(item)
          ? 'active'
          : ''
      ">
        <i v-if="item.is_heading && hideListMenuTitle" class="ri-subtract-line" />
        <span v-if="item.is_heading && hideListMenuTitle">{{ item.name }}</span>
        <router-link :to="item.link" v-if="!item.is_heading" :class="`iq-waves-effect ${
          activeLink(item) && item.children
            ? 'active'
            : activeLink(item)
            ? 'active'
            : ''
        }`" v-b-toggle="getId(item)">
            <i :class="item.icon" v-if="item.is_icon_class" />
            <template v-else v-html="item.icon"> </template>
            <span>{{ item.name }}</span>
            <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
            <small v-html="item.append" v-if="hideListMenuTitle" :class="item.append_class" />
        </router-link>
        <List v-if="item.children" :items="item.children" :sidebarGroupTitle="hideListMenuTitle" :class="`iq-waves-effect ${
          activeLink(item) && item.children
            ? 'active'
            : activeLink(item)
            ? 'active'
            : ''
        }`" :open="
          item.link.name !== '' && activeLink(item) && item.children
            ? true
            : !!(item.link.name !== '' && activeLink(item))
        " :idName="getId(item)" :accordianName="`sidebar-accordion-${getId(item)}`" :className="getClassName(item)" />
    </li>
</b-collapse>
</template>

<script>
import List from "./CollapseMenu";
import {
    core
} from "../../../config/pluginInit";
export default {
    name: "List",
    props: {
        items: Array,
        className: {
            type: String,
            default: "iq-menu",
        },
        open: {
            type: Boolean,
            default: false,
        },
        idName: {
            type: String,
            default: "sidebar",
        },
        accordianName: {
            type: String,
            default: "sidebar",
        },
        sidebarGroupTitle: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        List,
    },
    computed: {
        hideListMenuTitle() {
            return this.sidebarGroupTitle;
        },
    },
    mounted() {

    },
    methods: {
        getClassName(item) {
            return (this.activeLink(item) && item.children ? `iq-submenu ${item.class_name} ` : ``) + (this.idName != 'sidebar' ? 'iq-submenu-data' : '');
        },
        getId(item) {
            return item.name.toLowerCase().replace(/&/g, "_").replace(/ /g, "_");
        },
        activeLink(item) {
            return core.getActiveLink(item, this.$route.name);
        },
    },
};
</script>

<style scoped>
.sidebar-main ul .collapse .show {
    padding-left: 0px;
}

.sidebar-main .iq-sidebar-menu .iq-menu li ul {
    padding-left: 0px;
    margin-left: 0.4em;
}

.collapse .show .iq-submenu-data {
    padding-left: 0px;
}

body.sidebar-main .iq-sidebar:hover li ul li>a {
    margin-left: 1.4em;
}
</style>

<template>
  <div>
    <div class="wrapper">
      <SidebarStyle :SideBarItems="SideBarItems" />
      <!-- <div class="chat-container">
        <ChatComponent />
      </div> -->
      <div class="content-page" id="content-page">
        <transition name="router-anim" enter-active-class="animated  fadeInUp" mode="out-in"
          leave-active-class="animated fadeOut">
          <router-view />
        </transition>
      </div>
    </div>
    <!-- <LayoutFooter /> -->
  </div>
</template>
<script>
import HeaderStyle from "../components/core/partials/HeaderStyle/HeaderStyle.vue";
import SidebarStyle from "../components/core/partials/SidebarStyle/SidebarStyle.vue";
import LayoutFooter from "../layouts/Components/LayoutFooter.vue";
import Loader from "../components/core/loader/Loader";
import NotificationBell from "../components/Global-Component/notificationbell/NotificationBell.vue";
// import ChatComponent from "../components/Global-Component/crm-chat-popup/crm-chat.vue";
import { _ } from "vue-underscore";

export default {
  name: "Layout2",
  components: {
    Loader,
    HeaderStyle,
    SidebarStyle,
    LayoutFooter,
    NotificationBell,
    // ChatComponent
  },

  props: {
    selectedLead: Object
  },
  data() {
    return {
      SideBarItems: [],
      // sessionTimer: null,
      // sessionTimeout: 0.5 * 60 * 1000,
      sessionTimer: null,
      idleTimer: null,
      sessionTimeout: 60 * 60 * 1000,
    };
  },
  methods: {

    logout() {
      const _vm = this;
      this.axios
        .get("/logout")
        .then(function () {
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
          localStorage.removeItem('all_leads');
          localStorage.removeItem('targetUpdated');
          localStorage.removeItem('all_sales');
          localStorage.removeItem('call_queue_presence');
          localStorage.removeItem('subscribedLeads');
          localStorage.removeItem('user');
          localStorage.removeItem('menu');
          localStorage.removeItem('slugs');
          window.location.href = "/auth/login";
        })
        .catch(function (error) {
        });
    },
    getId(item) {
      return item.name
        .toLowerCase()
        .replace(/&/g, "_")
        .replace(/ /g, "_");
    },
    checkChildLink(routes) {
      routes.forEach((route) => {
        if (route.path === item.menu_link) {
          routeName = route.name;
        }
      });
    },
    getLinkName(routes, item) {
      if (item.menu_link === "/") {
        return "dashboard.home-1";
      }
      let routeName = null;
      routes.forEach((route) => {
        if (route.path === item.menu_link) {
          routeName = route.name;
        }
        if (!routeName) {
          if (route.children && route.children.length > 0) {
            routeName = this.getLinkName(route.children, item);
          }
        }
      });
      return routeName;
    },
    wrapMenu(data, isSubMenu = false) {
      let menuList = [];
      data.forEach((menu) => {
        let newMenu = {
          title: menu.name,
          name: menu.name,
          is_heading: false,
          is_active: false,
          link: {
            name: this.getLinkName(
              this.$router.options.routes,
              menu
            ),
          },
          class_name: "",
          is_icon_class: true,
          icon: menu.menu_icon,
        };
        if (menu.child.length > 0) {
          newMenu["children"] = this.wrapMenu(menu.child, true);
          if (!isSubMenu) {
            newMenu["link"] = "";
          }
        }
        menuList.push(newMenu);
      });
      return menuList;
    },
    // startSessionTimer() {
    // 	this.sessionTimer = setTimeout(() => {
    // 		this.logout();
    // 	}, this.sessionTimeout);

    // 	const resetTimers = () => {
    // 		clearTimeout(this.idleTimer);
    // 		clearTimeout(this.sessionTimer);
    // 		this.idleTimer = setTimeout(() => {
    // 			this.logout();
    // 		}, this.sessionTimeout);
    // 		this.sessionTimer = setTimeout(() => {
    // 			this.logout();
    // 		}, this.sessionTimeout);
    // 	};
    // 	document.addEventListener('mousemove', resetTimers);
    // 	document.addEventListener('keydown', resetTimers);
    // 	document.addEventListener('mousedown', resetTimers);
    // 	resetTimers();
    // },
    // beforeDestroy() {
    // 	clearTimeout(this.sessionTimer);
    // 	document.removeEventListener('mousemove', this.resetIdleTimer);
    // 	document.removeEventListener('keydown', this.resetIdleTimer);
    // 	document.removeEventListener('mousedown', this.resetIdleTimer);
    // 	clearTimeout(this.idleTimer);
    // },

  },
  mounted() {
    // this.startSessionTimer();

    document.body.classList = "";
    let _vm = this;
    let userData = localStorage.getItem('user');
    if (userData) {
      _vm.$store.state.userDetail = JSON.parse(atob(userData));
    } else {
      this.axios
        .get("/get-user")
        .then(function (response) {
          localStorage.setItem(
            'user',
            response.data.data,
          )
          _vm.$store.state.userDetail = JSON.parse(atob(response.data.data));
        });
    }

    let menuData = localStorage.getItem('menu');
    let slugsData = localStorage.getItem('slugs');
    if (menuData && slugsData) {
      _vm.$store.state.menuList = JSON.parse(atob(menuData));
      _vm.$store.state.slugsList = JSON.parse(atob(slugsData));
      _vm.SideBarItems = _vm.wrapMenu(JSON.parse(atob(menuData)));
    } else {
      this.axios
        .get("/menu")
        .then(function (response) {
          localStorage.setItem(
            'menu',
            response.data.data.menu,
          )
          localStorage.setItem(
            'slugs',
            response.data.data.slugs,
          )
          _vm.$store.state.menuList = JSON.parse(atob(response.data.data.menu));
          _vm.$store.state.slugsList = JSON.parse(atob(response.data.data.slugs));
          _vm.SideBarItems = _vm.wrapMenu(JSON.parse(atob(response.data.data.menu)));
        });
    }
  },
};
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");

.custom-notification-bell {
  position: fixed;
  /* top: 20px; */
  /* Change this to place it anywhere */
  right: 20px;
  /* Change this to place it anywhere */
  z-index: 9999;
}
</style>

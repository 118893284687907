<template>
<div>
  <footer class="iq-footer">
    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-12 text-right">
          Copyright 2020 <a href="#">{{ appName }}</a> All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
</div>
</template>
<script>
import FloatingButton from './FloatingButton.vue'
import { APPNAME } from '../../config/pluginInit'
export default {
  name: 'LayoutFooter',
  components: {
    FloatingButton
  },
  data () {
    return {
      appName: APPNAME
    }
  }
}
</script>
